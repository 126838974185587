<script lang="ts" setup>
  import '@bt/design-system/style.css'
  import { storeToRefs } from 'pinia'
  import { AccordionMenu, Text } from '@bt/design-system'
  import { useAppStore } from '@/stores'
  import { useBreakpoints } from '@/composables/useBreakpoints'
  import { SHOW_MENU_WHITELIST } from '@/utils/constants'

  import type MenuItemI from '@/types/Navigation/MenuItemI'

  const INDEX_PAGE_LAYOUT_KEY = 'index-page'

  const route = useRoute()
  const appStore = useAppStore()
  const { cmsData, menuData } = storeToRefs(appStore)
  const { isMobile } = useBreakpoints()

  const isIndexPage = computed(() => cmsData?.value?.content?.layout === INDEX_PAGE_LAYOUT_KEY)
  const showMenu = computed(() => SHOW_MENU_WHITELIST.some((path) => route.path.includes(path)))

  interface INavigationItem extends MenuItemI {
    isActive?: boolean
  }

  const activeMenu = computed(() => {
    let activeItem = ''

    menuData.value.forEach((menu: INavigationItem) => {
      if (menu.subItems) {
        menu.subItems.forEach((sMenu: INavigationItem) => {
          if (sMenu.isActive) {
            activeItem = sMenu.title
          }
        })
      }

      if (menu.isActive) {
        activeItem = menu.title
      }
    })

    return activeItem
  })

  const emits = defineEmits(['change'])
</script>

<template>
  <div
    v-if="cmsData?.content"
    class="app"
    :class="{ INDEX_PAGE_LAYOUT_KEY: isIndexPage }"
  >
    <slot name="navigation-header" />

    <div class="title-wrapper content-wrapper">
      <div
        v-if="cmsData.content.pageName"
        class="page-title"
      >
        <Text
          :text="cmsData.content.pageName"
          type="h1"
        />
      </div>
    </div>
    <AccordionMenu
      v-if="isMobile && showMenu"
      :title="activeMenu"
      class="mobile-sidebar-menu"
    >
      <template #menu="{ config }">
        <Menu
          :items="menuData"
          v-bind="config"
          @change="emits('change', $event)"
        />
      </template>
    </AccordionMenu>
    <HeroBanner
      v-if="cmsData.content.heroImage?.id"
      :hero-card="cmsData.content.heroCard"
      :hero-image="cmsData.content.heroImage"
      :is-index="isIndexPage"
    />
    <NuxtLayout
      :class="cmsData.content.layout || 'default'"
      :name="cmsData.content.layout || 'default'"
      @change="emits('change', $event)"
    >
      <NuxtPage
        v-if="cmsData.content"
        :content="cmsData.content"
      />
    </NuxtLayout>

    <slot name="footer" />
  </div>
</template>

<style lang="scss">
  @use 'assets/styles/common' as c;

  .content-wrapper {
    max-width: c.rem(1120);
    margin: auto;
    width: 100%;
  }

  .title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: c.rem(16);

    @include c.desktop-up {
      margin-bottom: c.rem(40);
    }
  }

  .page-title {
    font-size: c.rem(32);
    line-height: c.rem(38);
    color: c.$bt-blue;
    font-weight: 500;
    margin: auto c.rem(16) 0;

    @include c.desktop-up {
      font-size: c.rem(56);
      line-height: c.rem(66);
      margin: auto 0 c.rem(8);
    }
  }

  .mobile-sidebar-menu {
    margin: 0 c.rem(16) c.rem(16);

    @include c.tablet-up {
      margin-bottom: c.rem(32);
    }
  }

  .index-page {
    .header-wrapper,
    .title-wrapper,
    .navigation-wrapper {
      margin-bottom: 0;
    }

    .border-b-bt-ui-border {
      border-bottom: none;
    }
  }
</style>
